import React from 'react'

const Homesection = () => {
    return (
        <>

            <div className='section-bar'>

                <div className='container'>
                    <div className='row'>

                        <div className='col-lg-6 col-md-12'>
                            <h2>What Makes WhiteHatSEO Unique?</h2>
                            <div className='bar'></div>
                            <p>WHITEHATSEO is a top-notch digital marketing agency that helps small and large companies with their different social media marketing goals by using result-driven techniques for websites and their product-based services.</p>

                            <div className='row mt-4'>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-solid fa-database m-2"></i>
                                            <h6 className='m-0'>Customized Digital Marketing SolutionsCloud databases</h6>
                                        </div>

                                    </div>


                                </div>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-solid fa-thin fa-globe m-2"></i>
                                            <h6 className='m-0'>Dedicated Support and Regular Updates</h6>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-regular fa-file m-2"></i>
                                            <h6 className='m-0'>Increase in Website Traffic and Online Visibility</h6>
                                        </div>

                                    </div>


                                </div>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-solid fa-chart-simple m-2"></i>
                                            <h6 className='m-0'>Expertise in Latest Digital Marketing Trends</h6>
                                        </div>

                                    </div>


                                </div>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-regular fa-thin fa-folder m-2"></i>
                                            <h6 className='m-0'>Proven Success with Diverse Industries</h6>
                                        </div>

                                    </div>


                                </div>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-solid fa-desktop m-2" ></i>
                                            <h6 className='m-0'>Expertise in Latest Digital Marketing Trends</h6>
                                        </div>

                                    </div>


                                </div>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-regular fa-envelope m-2"></i>
                                            <h6 className='m-0'>Focus on quality leads and revenue </h6>
                                        </div>

                                    </div>


                                </div>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-solid fa-cloud m-2"></i>
                                            <h6 className='m-0'>Innovative Solutions and Data-Driven Decisions</h6>
                                        </div>

                                    </div>


                                </div>
                            </div>

                        </div>

                        <div className='col-lg-5'>
                            <div className='section-img position-relative'>
                                <img src='homeimages/table.section-bar.png' className='img1' alt='cloud' />
                                <img src='homeimages/cup.section-bar.png' className='img2' alt='table' />
                                <img src='homeimages/box.section-bar.png' className='img3' alt='box' />
                                <img src='homeimages/book-self.png' className='img4' alt='book-self' />
                                <img src='homeimages/wifi.section-bar.png' className='img5' alt='wifi' />
                                <img src='homeimages/tissue.section-bar.png' className='img6' alt='tissue' />
                                <img src='homeimages/cloud.section-bar.png' className='img7' alt='cloud' />
                                <img src='homeimages/monitor.section-bar.png' className='img8' alt='monitor' />
                                <img src='homeimages/flower.section-bar.png' className='img9' alt='flower' />
                                <img src='homeimages/chair.section-bar.png' className='img10' alt='chair' />
                                <img src='homeimages/water.section-bar.png' className='img11' alt='waterbottle' />
                                <img src='homeimages/mug.section-bar.png' className='img12' alt='mug' />
                                <img src='homeimages/headphone.section-bar.png' className='img13' alt='headphone' />
                            </div>


                            <div className='rotating-img'>
                                <img src='homeimages/rotating.png' className='img14' alt='animation' />
                            </div>


                            <div className='section-fullimg1'>
                                <img src='frontendImg/section-fullimg1.png' className='img1 w-100' alt='full' />
                            </div>



                        </div>
                    </div>
                </div>
            </div>

            <div className='section-bar2 '>

                <div className='container'>
                    <div className='row'>

                        <div className='col-lg-6'>
                            <div className='section-img position-relative'>
                                <img src='homeimages/bulb.sectionbar2.png' className='img1' alt='bulb'></img>
                                <img src='homeimages/target.sectionbar2.png' className='img2' alt='targetgame'></img>
                                <img src='homeimages/bigmonitor.sectionbar2.png' className='img3' alt='bigmonitor'></img>
                                <img src='homeimages/bigpot.sectionbar2.png' className='img4' alt='bigpot'></img>
                                <img src='homeimages/table.sectionbar2.png' className='img5' alt='table'></img>
                                <img src='homeimages/developerman.sectionbar2.png' className='img6' alt='man/chair'></img>
                                <img src='homeimages/smallmonitor.sectionbar2.png' className='img7' alt='smallmonitor'></img>
                                <img src='homeimages/smallpot.sectionbar2.png' className='img8' alt='smallpot'></img>
                            </div>

                            <div className='rotating1-img'>
                                <img src='homeimages/rotating1.png' className='img15' alt='animation' />


                            </div>

                            <div className='section-fullimg2'>
                            <img src='homeimages/section-fullimg2.png' className='img1' alt='full-img'></img>
                            </div>
                        </div>



                        <div className='col-lg-6 col-md-12'>
                            <h2>Why Choose WHITEHATSEO</h2>
                            <div className='bar'></div>
                            <p>WHITEHATSEO can provide numerous benefits for your business, especially if you want to improve your online visibility and drive targeted traffic to your website.</p>

                            <div className='row'>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-solid fa-file-pen m-2"></i>
                                            <h6 className='m-0'>Expertise and Experience</h6>
                                        </div>

                                    </div>


                                </div>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-solid fa-code m-2"></i>
                                            <h6 className='m-0'>Comprehensive SEO Strategy</h6>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-solid fa-mobile m-2"></i>
                                            <h6 className='m-0'>Time and Resource Efficiency
                                            </h6>
                                        </div>

                                    </div>


                                </div>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-brands fa-laravel m-2"></i>
                                            <h6 className='m-0'>Access to Advanced Tools and Technologies
                                            </h6>
                                        </div>

                                    </div>


                                </div>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-brands fa-apple m-2"></i>
                                            <h6 className='m-0'>Continuous Monitoring and Optimization
                                            </h6>
                                        </div>

                                    </div>


                                </div>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-solid fa-percent m-2"></i>
                                            <h6 className='m-0'>Expert Problem Solving
                                            </h6>
                                        </div>

                                    </div>


                                </div>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-solid fa-cart-shopping m-2"></i>
                                            <h6 className='m-0'>Avoiding Common Pitfalls
                                            </h6>
                                        </div>

                                    </div>


                                </div>
                                <div className='col-lg-6 col-md-6 mb-4'>
                                    <div className='card border-0 shadow-sm'>
                                        <div className='d-flex align-items-center px-2 py-3'>
                                            <i className="fa-solid fa-print m-2"></i>
                                            <h6 className='m-0'>Up-to-Date Knowledge</h6>
                                        </div>

                                    </div>


                                </div>
                            </div>

                        </div>


                    </div>









                </div>




            </div>


        </>


    )

}

export default Homesection
